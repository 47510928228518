import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import IconArrowDown from '../assets/images/icon_arrow_down_black.svg';

const displayList = [
  {
    locale: 'en',
    text: 'English',
  },
  {
    locale: 'zh-TW',
    text: '繁體中文',
  },
  {
    locale: 'zh-CN',
    text: '简体中文',
  },
  {
    locale: 'ja',
    text: '日本語',
  },
  {
    locale: 'ko',
    text: '한국어',
  },
  {
    locale: 'vi',
    text: 'Tiếng việt',
  },
  {
    locale: 'pt-BR',
    text: 'Português',
  },
  {
    locale: 'ru',
    text: 'Русский',
  },
  {
    locale: 'tr',
    text: 'Türkçe',
  },
];

const Container = styled.div`
position: relative;
`;

const CurrentLocale = styled.a`
font-size: 14px;
color: #333333;
padding-right: 14px;
background: url("${IconArrowDown}") center right no-repeat;
background-size: 7px auto;
cursor: pointer;
`;

const LocaleList = styled.ul`
width: 100px;
overflow: hidden;
background-color: #ffffff;
box-shadow: 0 0 10px 0 rgba(0,0,0,0.10);
border-radius: 0 0 8px 8px;
position: absolute;
top: 38px;
left: 50%;
z-index: 98;
transform: translate(-50%, 0);
transition: opacity 500ms;
will-change: opacity;
opacity: ${(props) => props.show ? '1' : '0'};
pointer-event: ${(props) => props.show ? 'auto' : 'none'};
`;

const LocaleItem = styled.li`

`;

const LocaleLink = styled.a`
display: block;
padding: 12px;
font-size: 14px;
cursor: pointer;
&:hover {
  background-color: #eaf1ff;
  color: #3674ff;
}
`;


class CompLanguageSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      showSelector: false,
    };

    this.showSelectorTimer = null;
    this.hideSelectorTimer = null;

    this.onLocaleItemClick = this.onLocaleItemClick.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <CurrentLocale onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                {
                  ((displayList.filter(item => item.locale === i18n.language) || [])[0] || displayList[0]).text || ''
                }
              </CurrentLocale>

              <LocaleList show={this.state.showSelector} onMouseEnter={this.onEnter} onMouseLeave={this.onLeave}>
                  {
                  displayList.map(item => (
                    <LocaleItem key={item.locale}>
                      <LocaleLink onClick={() => this.onLocaleItemClick(i18n, item)}>
                        {item.text}
                      </LocaleLink>
                    </LocaleItem>
                  ))
                  }
              </LocaleList>
            </Container>
          )
        }
      </Translation>
    );
  }

  onLocaleItemClick(i18n, item) {
    i18n.changeLanguage(item.locale);
  }

  onEnter() {
    window.clearTimeout(this.hideSelectorTimer);
      this.showSelectorTimer = window.setTimeout(() => {
        this.setState({
          showSelector: true,
        });
      }, 150);
  }

  onLeave() {
    window.clearTimeout(this.showSelectorTimer);
      this.hideSelectorTimer = window.setTimeout(() => {
        this.setState({
          showSelector: false,
        });
      }, 200);
  }
}

export default CompLanguageSelector;
