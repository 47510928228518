import axiosInstance from './httpClient';

export function getHistoricalVoteInfo() {
  return axiosInstance({
    url: '/api/getHistoricalVoteInfo',
    method: 'GET',
    params: {

    },
  });
}

export function getBpRewardHistory(period = '') {
  return axiosInstance({
    url: '/api/getBpRewardHistory',
    method: 'POST',
    data: {
      period
    },
  });
}

export function getBpEstimateReward() {
  return axiosInstance({
    url: '/api/getBpEstimateReward',
    method: 'POST',
    data: {

    },
  });
}
