import React from 'react';
import { Translation } from 'react-i18next';
import HistoryTable from '../components/HistoryTable';
import HistoryList from '../components/HistoryList';

class HistoryListWrapper extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <div>
              <div className="is-hidden-touch">
                <HistoryTable list={this.props.list}></HistoryTable>
              </div>
              <div className="is-hidden-desktop">
                <HistoryList list={this.props.list}></HistoryList>
              </div>
            </div>
          )
        }
      </Translation>
    );
  }
}

export default HistoryListWrapper;
