import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/logo.png';
import IconMenuBtn from '../assets/images/icon_menu_btn.svg';
import SidebarMobile from './SidebarMobile';
import { Translation } from 'react-i18next';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.header`
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(90)};
  line-height: ${px2rem(90)};
  background-color: #ffffff;
  padding: 0 ${px2rem(30)};
  text-align: center;
  position: relative;
}
`;

const LogoLink = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: block;
  width: ${px2rem(240)};
  height: 100%;
  margin: 0 auto;
}
`;

const LogoImg = styled.img`
@media screen and ${DEVICES.TOUCH} {
  width: 100%;
  height: auto;
  max-height: 100%;
  vertical-align: middle;
}
`;

const MenuBtn = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: block;
  position: absolute;
  left: ${px2rem(30)};
  top: 0;
  width: ${px2rem(40)};
  height: 100%;
  background: url("${IconMenuBtn}") center center no-repeat;
  background-size: 100% auto;
}
`;

class CompHeaderMobile extends React.Component {
  constructor() {
    super();
    this.state = {
      showSidebar: false,
    };

    this.onMenuBtnClick = this.onMenuBtnClick.bind(this);
    this.onSidebarClose = this.onSidebarClose.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <MenuBtn onClick={this.onMenuBtnClick}></MenuBtn>
              <LogoLink href="/">
                <LogoImg src={Logo}></LogoImg>
              </LogoLink>

              <SidebarMobile show={this.state.showSidebar} closeSidebar={this.onSidebarClose}></SidebarMobile>
            </Container>
          )
        }
      </Translation>
    );
  }

  onMenuBtnClick() {
    this.setState({
      showSidebar: true,
    });
  }

  onSidebarClose() {
    this.setState({
      showSidebar: false,
    });
  }
}

export default CompHeaderMobile;
