import React from 'react';
import { Translation } from 'react-i18next';
import BenefitTable from '../components/BenefitTable';
import BenefitList from '../components/BenefitList';

class BenefitListWrapper extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <div>
              <div className="is-hidden-touch">
                <BenefitTable list={this.props.list}></BenefitTable>
              </div>
              <div className="is-hidden-desktop">
                <BenefitList list={this.props.list}></BenefitList>
              </div>
            </div>
          )
        }
      </Translation>
    );
  }
}

export default BenefitListWrapper;
