import React from 'react';
import styled, {keyframes} from 'styled-components';
import { Translation } from 'react-i18next';
import LoadingBlue from '../assets/images/blue_loading.svg';

const Container = styled.div`
height: 100px;
`;

const Rotates  = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const IconLoading = styled.div`
height: 100px;
background: url("${LoadingBlue}") center center no-repeat;
background-size: 32px 32px;
animation: ${Rotates} 1.5s infinite linear;
`;

class CompListLoading extends React.Component {
  constructor() {
    super();
    this.state = {
      year: 2019,
    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <IconLoading></IconLoading>
            </Container>
          )
        }
      </Translation>
    );
  }
}

export default CompListLoading;
