import RulesView from './views/Rules';
import EstimationView from './views/Estimation';
import HistoryView from './views/History';

const routes = [
  {
    path: '/',
    exact: true,
    component: RulesView,
  },
  {
    path: '/history',
    component: HistoryView,
  },
  {
    path: '/estimation',
    component: EstimationView,
  },
];

export default routes;
