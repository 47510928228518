import React from 'react';
import styled from 'styled-components';
import Logo from '../assets/images/logo.png';
import { Translation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';

const Container = styled.header`
height: 60px;
background-color: #ffffff;
padding: 0 50px;
`;

const LogoLink = styled.a`
display: block;
height: 60px;
padding-top: 10px;
margin-right: 70px;
`;

const LogoImg = styled.img`
display: inline-block;
width: 160px;
height: auto;
`;

const HeaderLink = styled.a`
font-size: 14px;
color: #333333;
padding: 0 30px 0 0;
text-decoration: none;
`;

class CompHeader extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
        <div className="columns is-gapless is-vcentered">
          <div className="column">
            <div className="columns is-gapless is-vcentered">
              <div className="column is-narrow">
                <LogoLink href="/">
                  <LogoImg src={Logo}></LogoImg>
                </LogoLink>
              </div>
              <div className="column is-narrow">
                <HeaderLink href="https://www.contentos.io">
                  {t('headerNavAbout')}
                </HeaderLink>
              </div>
              <div className="column is-narrow">
                <HeaderLink href="https://explorer.contentos.io">
                  {t('headerNavExplorer')}
                </HeaderLink>
              </div>
            </div>
          </div>
          <div className="column is-narrow">
            <LanguageSelector></LanguageSelector>
          </div>
        </div>
      </Container>
          )
        }
      </Translation>
    );
  }
}

export default CompHeader;
