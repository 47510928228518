import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { px2rem, DEVICES} from '../utils/styles';
import {numberWithCommas, formatDecimal} from '../utils/index';
import {formatVestDisplay, formatPercent} from '../helpers/index';

const Container = styled.div`
@media screen and ${DEVICES.TOUCH} {
  padding-top: ${px2rem(20)};
}
`;

const List = styled.ul`
@media screen and ${DEVICES.TOUCH} {

}
`;

const RowVal = styled.span`
@media screen and ${DEVICES.TOUCH} {
  font-weight: 500;
}
`;

const ItemRow = styled.div`
@media screen and ${DEVICES.TOUCH} {
  font-weight: 200;
  line-height: 1.5;
  padding-bottom: ${px2rem(18)};
}
`;

const ListItem = styled.li`
@media screen and ${DEVICES.TOUCH} {
  margin-bottom: ${px2rem(20)};
  padding: ${px2rem(20)} ${px2rem(20)} 0 ${px2rem(20)};
  color: #666666;
  font-size: ${px2rem(24)};
  word-break: break-word;
  border-radius: ${px2rem(4)};
  background-color: #ffffff;
  background-image: ${(props) => props.official ? 'linear-gradient(180deg, rgba(11, 164, 229, .7) 0%, rgba(25, 133, 232, .7) 100%)' : 'none'};
  color: ${(props) => props.official ? '#ffffff' : '#666666'};
  ${RowVal} {
    color: ${(props) => props.official ? '#ffffff' : '#000000'};
  }
}
`;

class CompRuleBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      sortItem: 'EstimatedThousandRewards',
      sortOrder: 'desc',
      sortedList: [],
    };

    this.onThLinkClick = this.onThLinkClick.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <List>
                {
                  this.state.sortedList.map((item, index) => (
                    <ListItem key={item.BpName} official={/^cosofficialbp[1-7]$/.test(item.BpName )}>
                      <ItemRow>#&nbsp;&nbsp;<RowVal>{index + 1}</RowVal></ItemRow>
                      <ItemRow>{t('thBpName')}:&nbsp;&nbsp;<RowVal>{item.BpName}</RowVal></ItemRow>
                      <ItemRow>{t('thProducing')}:&nbsp;&nbsp;<RowVal>{item.IsTop21 ? t('producingYes') : t('producingNo')}</RowVal></ItemRow>
                      <ItemRow>{t('thGenBlockCount')}:&nbsp;&nbsp;<RowVal>{numberWithCommas(item.GenBlockCount)}</RowVal></ItemRow>
                      <ItemRow>{t('thAccumulatedReward')}:&nbsp;&nbsp;<RowVal>{numberWithCommas(formatDecimal(item.AccumulatedReward))} VEST</RowVal></ItemRow>
                      <ItemRow>{t('thEstimatedTotalReward')}:&nbsp;&nbsp;<RowVal>{numberWithCommas(formatDecimal(item.EstimatedTotalReward))} VEST</RowVal></ItemRow>
                      <ItemRow>{t('thRewardRate')}:&nbsp;&nbsp;<RowVal>{formatPercent(item.RewardRate)}</RowVal></ItemRow>
                      <ItemRow>{t('thEstimatedVotersVest')}:&nbsp;&nbsp;<RowVal>{numberWithCommas(formatDecimal(item.EstimatedVotersVest))} VEST</RowVal></ItemRow>
                      <ItemRow>{t('thEstimatedThousandRewards')}:&nbsp;&nbsp;<RowVal>{formatVestDisplay(item.EstimatedThousandRewards)}</RowVal></ItemRow>
                    </ListItem>
                  ))
                }
              </List>
            </Container>
          )
        }
      </Translation>
    );
  }

  componentDidMount() {
    this.sortList(this.state.sortItem, this.state.sortOrder);
  }

  onThLinkClick(type) {
    if (type === this.state.sortItem) {
      this.setState({
        sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc',
      }, () => {
        this.sortList(type, this.state.sortOrder);
      });
      return;
    }

    this.setState({
      sortItem: type,
      sortOrder: 'desc',
    }, () => {
      this.sortList(type, this.state.sortOrder);
    });
  }

  sortList(filed = '', order = '') {
    const sorted = this.props.list.sort(function(a, b) {
      if (order === 'desc') {
        return b[filed] - a[filed];
      }

      return a[filed] - b[filed];
    });

    this.setState({
      sortedList: sorted,
    });
  }
}

export default CompRuleBanner;
