import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import BgBanner from '../assets/images/rule_banner.png';
import BgBannerMobile from '../assets/images/rule_banner_mobile.png';
import IconCos from '../assets/images/icon_cos.png';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
height: 230px;
background: #242e55 url(${BgBanner}) center center no-repeat;
background-size: 100% auto;
position: relative;
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(180)};
  background: #242e55 url(${BgBannerMobile}) center center no-repeat;
  background-size: 100% auto;
}
`;

const Desc1 = styled.div`
font-size: 36px;
color: #ffffff;
font-weight: 600;
padding-left: 437px;
padding-top: 69px;
vertical-align: middle;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(28)};
  padding-left: ${px2rem(235)};
  padding-top: ${px2rem(31)};
}
`;

const Desc2 = styled.div`
font-size: 24px;
color: #ffffff;
padding-left: 437px;
padding-top: 10px;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  padding-left: ${px2rem(235)};
  padding-top: ${px2rem(6)};
}
`;

const ItemCos = styled.img`
width: 38px;
height: 38px;
vertical-align: middle;
@media screen and ${DEVICES.TOUCH} {
  width: ${px2rem(28)};
  height: ${px2rem(28)};
}
`;

class CompRuleBanner extends React.Component {
  constructor() {
    super();
    this.state = {

    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <Desc1>
                {t('ruleBannerDesc01')}<ItemCos src={IconCos}></ItemCos>
              </Desc1>
              <Desc2>
                {t('ruleBannerDesc02')}
              </Desc2>
            </Container>
          )
        }
      </Translation>
    );
  }
}

export default CompRuleBanner;
