import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import homeEn from './json/home/en.json';
import homeZhTw from './json/home/zh-tw.json';
import homeZhCn from './json/home/zh-cn.json';
import homeKo from './json/home/ko.json';
import homeJa from './json/home/ja.json';
import homeVi from './json/home/vi.json';
import homePtBr from './json/home/pt-br.json';
import homeRu from './json/home/ru.json';
import homeTr from './json/home/tr.json';

i18n
  .use(LanguageDetector)
  // .use(reactI18nextModule) // if not using I18nextProvider
  .init({
    fallbackLng: 'en',
    defaultNS: 'home',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    react: {
      wait: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    },
    resources: {
      en: {
        home: homeEn,
      },
      'zh-TW': {
        home: homeZhTw,
      },
      'zh-CN': {
        home: homeZhCn,
      },
      ko: {
        home: homeKo,
      },
      ja: {
        home: homeJa,
      },
      vi: {
        home: homeVi,
      },
      'pt-BR': {
        home: homePtBr,
      },
      ru: {
        home: homeRu,
      },
      tr: {
        home: homeTr,
      },
    },
  });

export default i18n;
