import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { withRouter } from "react-router";
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
position: absolute;
left: 0;
bottom: 0;
padding: 3px 30px;
@media screen and ${DEVICES.TOUCH} {
  display: none;
}
`;

const NavItem = styled.div`
padding-right: 100px;
`;

const NavLink = styled.a`
display: inline-block;
font-size: 20px;
color: ${(props) => props.current ? '#17bafe' : '#ffffff'};
padding-bottom: 4px;
text-decoration: none;
border-bottom: 4px solid transparent;
border-color: ${(props) => props.current ? '#17bafe' : 'transparent'}
`;

class CompPageNav extends React.Component {
  constructor() {
    super();
    this.state = {

    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <div className="columns is-gapless">
                <div className="column is-narrow">
                  <NavItem>
                    <NavLink href="/" current={this.props.location.pathname === '/'}>{t('navRules')}</NavLink>
                  </NavItem>
                </div>
                <div className="column is-narrow">
                  <NavItem>
                    <NavLink href="/history" current={this.props.location.pathname === '/history'}>{t('navHistory')}</NavLink>
                  </NavItem>
                </div>
                <div className="column is-narrow">
                  <NavItem>
                    <NavLink href="/estimation" current={this.props.location.pathname === '/estimation'}>{t('navEstimation')}</NavLink>
                  </NavItem>
                </div>
              </div>
            </Container>
          )
        }
      </Translation>
    );
  }
}

export default withRouter(CompPageNav);
