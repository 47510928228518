import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import HistoryListWrapper from '../components/HistoryListWrapper';
import PeriodSelector from '../components/PeriodSelector';
import ListLoading from '../components/ListLoading';
import {getBpRewardHistory} from '../api/apiHome';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
background-color: #f6f6f6;
padding: 0 0 50px 0;
@media screen and ${DEVICES.TOUCH} {
  padding: 0;
}
`;

const ContentContainer = styled.div`
background-color: #ffffff;
padding: 20px 30px 163px 30px;
position: relative;
@media screen and ${DEVICES.TOUCH} {
  background-color: #f6f6f6;
  padding: ${px2rem(20)} ${px2rem(30)};
}
`;

const RuleItemTitle = styled.div`
font-size: 16px;
color: #333333;
font-weight: 600;
margin-bottom: 4px;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  margin-bottom: ${px2rem(17)};
}
`;

const RuleTextWrapper = styled.ul`
list-style: disc;
padding-left: 20px;
@media screen and ${DEVICES.TOUCH} {
  padding-left: ${px2rem(32)};
}
`;

const RuleItemText = styled.li`
font-size: 14px;
color: #333333;
line-height: 1.7;
list-style: disc;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
}
`;

class HistoryView extends React.Component {
  constructor() {
    super();
    this.state = {
      allList: [],
      listIndex: 0,
    };

    this.onChangeList = this.onChangeList.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <ContentContainer className="container">
                <PeriodSelector
                list={this.state.allList}
                index={this.state.listIndex}
                changeList={this.onChangeList}></PeriodSelector>

                {
                  this.state.allList.length > 0
                  ? <HistoryListWrapper
                  list={this.state.allList[this.state.listIndex].List}
                  key={this.state.listIndex}></HistoryListWrapper>
                  : <ListLoading></ListLoading>
                }

                <RuleItemTitle>
                  {t('estimationDescTitle')}
                </RuleItemTitle>

                <RuleTextWrapper>
                  <RuleItemText dangerouslySetInnerHTML={{__html: t('historyDesc01')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('historyDesc02')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('historyDesc03')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('historyDesc04')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('historyDesc05')}}>
                  </RuleItemText>
                </RuleTextWrapper>
              </ContentContainer>
            </Container>
          )
        }
      </Translation>
    );
  }

  componentDidMount() {
    this.getBpHistoryReward();
  }

  async getBpHistoryReward() {
    try {
      const response = await getBpRewardHistory();

      if (response.status === 200 && response.data && response.data.Status === 200) {
        const list = response.data.List || [];
        const length = list.length || 0;
        this.setState({
          allList: list,
          listIndex: length >= 1 ? length - 1 : 0,
        });
      }
    } catch(error) {

    }
  }

  onChangeList(index) {
    this.setState({
      listIndex: index,
    });
  }
}

export default HistoryView;
