export function numberWithCommas(num = 0) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDecimal(numOrStr, precision = 1) {
  numOrStr = `${numOrStr}`;

  const splited = numOrStr.split('.');
  return `${splited[0]}.${(splited[1] || '0').slice(0, precision)}`;
}
