import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import IconSortDesc from '../assets/images/icon_sort_desc.png';
import IconSortAsc from '../assets/images/icon_sort_asc.png';
import {numberWithCommas, formatDecimal} from '../utils/index';
import {formatVestDisplay, formatPercent} from '../helpers/index';

const Container = styled.div`
padding: 10px 0 30px 0;
`;

const Table = styled.table`
width: 100%;
border-collapse: collapse;
`;

const THeader = styled.thead`
background-color: rgba(23, 186, 254, .2);
`;

const Th = styled.th`
padding: 4px 0;
font-size: 10px;
color: #333333;
text-align: center;
font-weight: 500;
`;

const ThLink = styled.a`
display: inline-block;
position: relative;
text-decoration: none;
font-weight: ${(props) => props.current ? '600' : '400'};
padding: 0 12px;
cursor: pointer;
&:after {
  position: absolute;
  right: 0;
  top: 50%;
  content: " ";
  width: 6px;
  height: 12px;
  background: url("${(props) => {
    if (!props.current) {
      return '';
    }
    return props.order === 'asc' ? IconSortAsc : IconSortDesc;
  }}") center right no-repeat;
  background-size: 6px auto;
  transform: translate(0, -50%);
}
&:hover {
  &:after {
    background: url("${(props) => {
      if (props.current) {
        return props.order === 'asc' ? IconSortAsc : IconSortDesc;
      }
      return IconSortDesc;
    }}") center right no-repeat;
    background-size: 6px auto;
    opacity: ${(props) => props.current ? '1': '.5'};
  }
}
`;

const Td = styled.td`
padding: 12px 0;
min-width: 36px;
font-size: 12px;
color: #000000;
text-align: center;
border: 1px solid #e8e9ed;
background-color: #ffffff;
`;

const Tr = styled.tr`
${Td} {
  background-image: ${(props) => props.official ? 'linear-gradient(180deg, rgba(11, 164, 229, .8) 0%, rgba(25, 133, 231, .8) 100%)' : 'none'};
  color: ${(props) => props.official ? '#ffffff' : '#000000'};
}
`;

class CompRuleBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      sortItem: 'EstimatedThousandRewards',
      sortOrder: 'desc',
      sortedList: [],
    };

    this.onThLinkClick = this.onThLinkClick.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <Table>
                <THeader>
                  <tr>
                    <Th>#</Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'BpName'}
                    onClick={() => this.onThLinkClick('BpName')}>{t('thBpName')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'IsTop21'}
                    onClick={() => this.onThLinkClick('IsTop21')}>{t('thProducing')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'GenBlockCount'}
                    onClick={() => this.onThLinkClick('GenBlockCount')}>{t('thGenBlockCount')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'AccumulatedReward'}
                    onClick={() => this.onThLinkClick('AccumulatedReward')}>{t('thAccumulatedReward')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'EstimatedTotalReward'}
                    onClick={() => this.onThLinkClick('EstimatedTotalReward')}>{t('thEstimatedTotalReward')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'RewardRate'}
                    onClick={() => this.onThLinkClick('RewardRate')}>{t('thRewardRate')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'EstimatedVotersVest'}
                    onClick={() => this.onThLinkClick('EstimatedVotersVest')}>{t('thEstimatedVotersVest')}</ThLink></Th>
                    <Th><ThLink order={this.state.sortOrder} current={this.state.sortItem === 'EstimatedThousandRewards'}
                    onClick={() => this.onThLinkClick('EstimatedThousandRewards')}>{t('thEstimatedThousandRewards')}</ThLink></Th>
                  </tr>
                </THeader>
                <tbody>
                  {
                    this.state.sortedList.map((item, index) => (
                      <Tr key={item.BpName} official={/^cosofficialbp[1-7]$/.test(item.BpName )}>
                        <Td>{index + 1}</Td>
                        <Td>{item.BpName}</Td>
                        <Td>{item.IsTop21 ? t('producingYes') : t('producingNo')}</Td>
                        <Td>{numberWithCommas(item.GenBlockCount)}</Td>
                        <Td>{numberWithCommas(formatDecimal(item.AccumulatedReward))} VEST</Td>
                        <Td>{numberWithCommas(formatDecimal(item.EstimatedTotalReward))} VEST</Td>
                        <Td>{formatPercent(item.RewardRate)}</Td>
                        <Td>{numberWithCommas(formatDecimal(item.EstimatedVotersVest))} VEST</Td>
                        <Td>{formatVestDisplay(item.EstimatedThousandRewards)}</Td>
                      </Tr>
                    ))
                  }
                </tbody>
              </Table>
            </Container>
          )
        }
      </Translation>
    );
  }

  componentDidMount() {
    this.sortList(this.state.sortItem, this.state.sortOrder);
  }

  onThLinkClick(type) {
    if (type === this.state.sortItem) {
      this.setState({
        sortOrder: this.state.sortOrder === 'desc' ? 'asc' : 'desc',
      }, () => {
        this.sortList(type, this.state.sortOrder);
      });
      return;
    }

    this.setState({
      sortItem: type,
      sortOrder: 'desc',
    }, () => {
      this.sortList(type, this.state.sortOrder);
    });
  }

  sortList(filed = '', order = '') {
    const sorted = this.props.list.sort(function(a, b) {
      if (order === 'desc') {
        return b[filed] - a[filed];
      }

      return a[filed] - b[filed];
    });

    this.setState({
      sortedList: sorted,
    });
  }
}

export default CompRuleBanner;
