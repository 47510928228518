import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import IconArrowDown from '../assets/images/icon_arrow_down_black.svg';
import { px2rem, overflowEllipsis, DEVICES} from '../utils/styles';

const displayList = [
  {
    locale: 'en',
    text: 'English',
  },
  {
    locale: 'zh-TW',
    text: '繁體中文',
  },
  {
    locale: 'zh-CN',
    text: '简体中文',
  },
  {
    locale: 'ja',
    text: '日本語',
  },
  {
    locale: 'ko',
    text: '한국어',
  },
  {
    locale: 'vi',
    text: 'Tiếng việt',
  },
  {
    locale: 'pt-BR',
    text: 'Português',
  },
  {
    locale: 'ru',
    text: 'Русский',
  },
  {
    locale: 'tr',
    text: 'Türkçe',
  },
];

const Container = styled.div`
position: relative;
@media screen and ${DEVICES.TOUCH} {
  text-align: left;
}
`;

const CurrentLocale = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: inline-block;
  height: ${px2rem(90)};
  font-size: ${px2rem(30)};
  color: #333333;
  padding: 0 ${px2rem(26)} 0 ${px2rem(50)};
  position: relative;
  &::after {
    display: block;
    content: " ";
    position: absolute;
    width: ${px2rem(16)};
    height: ${px2rem(8)};
    top: ${px2rem(42)};
    right: 0;
    background: url("${IconArrowDown}") center center no-repeat;
    background-size: 100% auto;
    transform: ${(props) => props.show ? 'rotate(180deg)' : 'rotate(0)'};
    transition: transform 300ms;
  }
}
`;

const LocalListWrapper = styled.div`
@media screen and ${DEVICES.TOUCH} {
  padding: 0 0 0 ${px2rem(50)};
  overflow: hidden;
  max-height: ${(props) => props.show ? px2rem(250) : '0'};
  transition: max-height 300ms;
}
`;

const LocaleList = styled.div`

`;

const LocaleItem = styled.div`

`;

const LocaleLink = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: block;
  width: ${px2rem(148)};
  height: ${px2rem(54)};
  line-height: ${px2rem(54)};
  font-size: ${px2rem(26)};
  text-align: center;
  border: ${px2rem(2)} solid ${(props) => props.current ? '#3674ff' : '#333333'};
  border-radius: ${px2rem(8)};
  margin: 0 ${px2rem(20)} ${px2rem(30)} 0;
  color: ${(props) => props.current ? '#3674ff' : '#333333'};
  background-color: ${(props) => props.current ? '#eaf1ff' : '#ffffff'};
  ${overflowEllipsis()};
}
`;


class CompLanguageSelectorMobile extends React.Component {
  constructor() {
    super();
    this.state = {
      showSelector: false,
    };

    this.onLocaleItemClick = this.onLocaleItemClick.bind(this);
    this.onCurrentLocaleClick = this.onCurrentLocaleClick.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <CurrentLocale onClick={this.onCurrentLocaleClick} show={this.state.showSelector}>
                {
                  ((displayList.filter(item => item.locale === i18n.language) || [])[0] || displayList[0]).text || ''
                }
              </CurrentLocale>

              <LocalListWrapper show={this.state.showSelector}>
                <LocaleList className="columns is-gapless is-mobile is-multiline" >
                  {
                    displayList.map(item => (
                      <LocaleItem className="column is-narrow" current={i18n.language === item.locale} key={item.locale}>
                        <LocaleLink onClick={() => this.onLocaleItemClick(i18n, item)} current={i18n.language === item.locale} key={item.locale}>
                          {item.text}
                        </LocaleLink>
                      </LocaleItem>
                    ))
                  }
                </LocaleList>
              </LocalListWrapper>
            </Container>
          )
        }
      </Translation>
    );
  }

  onLocaleItemClick(i18n, item) {
    i18n.changeLanguage(item.locale);
    this.setState({
      showSelector: false,
    });
  }

  onCurrentLocaleClick() {
    this.setState({
      showSelector: !this.state.showSelector,
    });
  }
}

export default CompLanguageSelectorMobile;
