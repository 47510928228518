import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.footer`
height: 60px;
background-color: #242e55;
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(90)};
}
`;

const CopyWrite = styled.div`
text-align: center;
font-size: 14px;
opacity: .6;
font-weight: 200;
color: #ffffff;
line-height: 60px;
@media screen and ${DEVICES.TOUCH} {
  text-align: center;
  font-size: ${px2rem(20)};
  line-height: ${px2rem(90)};
}
`;

const ServiceLink = styled.a`
color: #ffffff;
text-decoration: none;
`;

class CompFooter extends React.Component {
  constructor() {
    super();
    this.state = {
      year: 2019,
    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <CopyWrite>
                {t('footerCopyWrite', {year: this.state.year})} | <ServiceLink href="https://www.contentos.io/subject/auxiliary/policy/dist/terms/">{t('footerService')} </ServiceLink>
              </CopyWrite>
            </Container>
          )
        }
      </Translation>
    );
  }
}

export default CompFooter;
