import React from 'react';
import { Translation } from 'react-i18next';
import Header from './Header';
import HeaderMobile from './HeaderMobile';

class HeaderWrapper extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <div>
              <div className="is-hidden-desktop">
                <HeaderMobile></HeaderMobile>
              </div>
              <div className="is-hidden-touch">
                <Header></Header>
              </div>
            </div>
          )
        }
      </Translation>
    );
  }
}

export default HeaderWrapper;
