import React from 'react';
import styled from 'styled-components';
import { withRouter } from "react-router";
import IconCloseBtn from '../assets/images/icon_close.svg';
import LanguageSelectorMobile from './LanguageSelectorMobile';
import { Translation } from 'react-i18next';
import { px2rem, overflowEllipsis, DEVICES} from '../utils/styles';

const Container = styled.div`
@media screen and ${DEVICES.TOUCH} {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  opacity: ${(props) => props.show ? 1 : 0};
  transform: translate(${(props) => props.show ? 0 : '-100%'}, 0);
  transition: transform 300ms, opacity 200ms;
}
`;

const SideBox = styled.div`
@media screen and ${DEVICES.TOUCH} {
  width: ${px2rem(580)};
  height: 100%;
  background-color: #ffffff;
}
`;

const TopBar = styled.div`
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(125)};
  position: relative;
}
`;

const CloseBtn = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: block;
  width: ${px2rem(45)};
  height: ${px2rem(45)};
  position: absolute;
  top: ${px2rem(20)};
  right: ${px2rem(20)};
  background: url("${IconCloseBtn}") center center no-repeat;
  background-size: 60% auto;
}
`;

const NavList = styled.ul`

`;

const NavItem = styled.li`

`;

const NavLink = styled.a`
@media screen and ${DEVICES.TOUCH} {
  display: block;
  height: ${px2rem(90)};
  line-height: ${px2rem(90)};
  font-size: ${px2rem(30)};
  text-align: left;
  text-decoration: none;
  padding: 0 ${px2rem(50)};
  color: ${(props) => props.current ? '#232c52' : '#333333'};
  background-color: ${(props) => props.current ? 'rgba(211, 229, 252, .9)' : '#ffffff'};
  ${overflowEllipsis()}
}
`;

class CompSidebarMobile extends React.Component {
  constructor() {
    super();

    this.onCloseBtnClick = this.onCloseBtnClick.bind(this);
    this.onContainerClick = this.onContainerClick.bind(this);
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container show={this.props.show} onClick={(event) => this.onContainerClick(event)}>
              <SideBox>
                <TopBar>
                  <CloseBtn onClick={this.onCloseBtnClick}></CloseBtn>
                </TopBar>

                <NavList>
                  <NavItem>
                    <NavLink href="https://www.contentos.io">
                      {t('headerNavAbout')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="https://explorer.contentos.io">
                      {t('headerNavExplorer')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/" current={this.props.location.pathname === '/'}>
                      {t('navRules')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/history" current={this.props.location.pathname === '/history'}>
                      {t('navHistory')}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="/estimation" current={this.props.location.pathname === '/estimation'}>
                      {t('navEstimation')}
                    </NavLink>
                  </NavItem>
                </NavList>

                <LanguageSelectorMobile></LanguageSelectorMobile>
              </SideBox>
            </Container>
          )
        }
      </Translation>
    );
  }

  onCloseBtnClick() {
    if (this.props.closeSidebar) {
      this.props.closeSidebar();
    }
  }

  onContainerClick(event) {
    if (event
      && event.target
      && event.target === event.currentTarget
      && this.props.closeSidebar) {
      this.props.closeSidebar();
    }
  }
}

export default withRouter(CompSidebarMobile);
