import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import BenefitListWrapper from '../components/BenefitListWrapper';
import ListLoading from '../components/ListLoading';
import {getBpEstimateReward} from '../api/apiHome';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
background-color: #f6f6f6;
padding: 0 0 50px 0;
@media screen and ${DEVICES.TOUCH} {
  padding: 0;
}
`;

const ContentContainer = styled.div`
background-color: #ffffff;
padding: 20px 30px 163px 30px;
@media screen and ${DEVICES.TOUCH} {
  background-color: #f6f6f6;
  padding: ${px2rem(20)} ${px2rem(30)};
}
`;

const RuleItemTitle = styled.div`
font-size: 16px;
color: #333333;
font-weight: 600;
margin-bottom: 4px;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  margin-bottom: ${px2rem(17)};
}
`;

const RuleTextWrapper = styled.ul`
list-style: disc;
padding-left: 20px;
@media screen and ${DEVICES.TOUCH} {
  padding-left: ${px2rem(32)};
}
`;

const RuleItemText = styled.li`
font-size: 14px;
color: #333333;
line-height: 1.7;
list-style: disc;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
}
`;

const DueTimeItem1 = styled.div`
font-size: 16px;
color: #333333;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(28)};
}
`;

const DueTimeItem2 = styled.div`
font-size: 16px;
color: #333333;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(22)};
}
`;

const BlockNumberRange = styled.div`
font-size: 14px;
color: #999999;
font-weight: 200;
padding: 4px 0 0 0;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(22)};
  padding: ${px2rem(8)} 0 ${px2rem(8)} ${px2rem(3)};
}
`;

const NotStartTip = styled.div`
font-size: 16px;
color: #333333;
padding: 8px 0;
text-align: center;
`;

const firstStartDateStr = '2019/10/23';
const firstEndDateStr = '2019/10/30';
const firstDateTimeStr = '2019/10/23 15:00:00+0800';
const firstDateTimestamp = new Date(firstDateTimeStr).getTime();
const nowTimestamp = Date.now();

class EstimationView extends React.Component {
  constructor() {
    super();
    this.state = {
      firstStartBlockNum: '2419200',
      firstEndBlockNum: '3024000',
      notStart: (nowTimestamp - firstDateTimestamp) < 0,
      DistributeTime: '',
      StartBlockNumber: '',
      EndBlockNumber: '',
      UpdateTime: '',
      List: [],
    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <ContentContainer className="container">
                <div>
                  <div className="columns is-gapless">
                    <div className="column">
                      <DueTimeItem1>
                        {t('estimationDate', {date: this.state.notStart ? firstEndDateStr : new Date(this.state.DistributeTime * 1000).toLocaleDateString(i18n.language)})}
                      </DueTimeItem1>
                    </div>
                    <div className="column is-narrow is-hidden-touch">
                      <DueTimeItem2>
                        {t('estimationUpdateDate', {date: new Date(this.state.UpdateTime * 1000).toLocaleString(i18n.language, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false,
                        })})}
                      </DueTimeItem2>
                    </div>
                  </div>
                </div>

                <BlockNumberRange>
                  {t('blockNumberRange', {value: `${this.state.notStart ? this.state.firstStartBlockNum : this.state.StartBlockNumber}-${this.state.notStart ? this.state.firstEndBlockNum : this.state.EndBlockNumber}`})}
                </BlockNumberRange>

                <DueTimeItem2 className="is-hidden-desktop">
                  {t('estimationUpdateDate', {date: new Date(this.state.UpdateTime * 1000).toLocaleString(i18n.language, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false,
                  })})}
                </DueTimeItem2>

                {
                  this.state.notStart && (
                    <NotStartTip>{t('notStartTips', {date: firstStartDateStr})}</NotStartTip>
                  )
                }

                {
                  this.state.List.length > 0
                  ? <BenefitListWrapper list={this.state.List}></BenefitListWrapper>
                  : <ListLoading></ListLoading>
                }

                <RuleItemTitle>
                  {t('estimationDescTitle')}
                </RuleItemTitle>

                <RuleTextWrapper>
                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc01')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc02')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc03')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc04')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc05')}}>
                  </RuleItemText>

                  <RuleItemText dangerouslySetInnerHTML={{__html: t('estimationDesc06')}}>
                  </RuleItemText>
                </RuleTextWrapper>
              </ContentContainer>
            </Container>
          )
        }
      </Translation>
    );
  }

  componentDidMount() {
    this.getBpEstimateReward();
  }

  async getBpEstimateReward() {
    try {
      const response = await getBpEstimateReward();

    if (response.status === 200 && response.data && response.data.Status === 200) {
      this.setState({
        ...response.data.Info || {
          StartBlockNumber: '',
          EndBlockNumber: '',
          DistributeTime: '',
          UpdateTime: '',
          List: [],
        },
      });
    }
    } catch(error) {

    }
  }
}

export default EstimationView;
