import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import IconArrowDownWhite from '../assets/images/icon_arrow_down_white.png';
import { px2rem, clearfix, DEVICES} from '../utils/styles';

const Container = styled.div`
padding: 0 0 10px 0;
@media screen and ${DEVICES.TOUCH} {
  padding: 0 0 ${px2rem(20)} 0;
}
`;

const PeriodList = styled.div`

`;

const PeriodContent = styled.div`
text-align: center;
padding-right: 40px;
`;

const RowDate = styled.a`
display: block;
border: 1px solid #1f284c;
border-radius: 4px;
padding: 6px 10px;
color: #333333;
text-decoration: none;
cursor: pointer;
`;

const RowBlockNumber = styled.div`
font-size: 14px;
color: #999999;
font-weight: 200;
padding-top: 7px;
`;

const PeriodItem = styled.div`
${RowDate} {
  color: ${(props) => props.current ? '#ffffff' : '#333333'};
  background-color: ${(props) => props.current ? '#1f284c' : '#ffffff'};
  &:hover {
  color: #ffffff;
  background-color: #1f284c;
}
}
`;

const PeriodMobileContainer = styled.div`
@media screen and ${DEVICES.TOUCH} {
  position: relative;
}
`;

const CurrentPeriodDisplayWrapper = styled.div`
background: #1f284c;
background-clip: content-box;
padding: 0 ${px2rem(50)} 0 0;
position: absolute;
z-index: 2;
left: 0;
top: 0;
width: 100%;
height: ${px2rem(90)};
`;

const PeriodBtnMobile = styled.div`
@media screen and ${DEVICES.TOUCH} {
  display: flex;
  align-items: center;
  height: ${px2rem(90)};
  font-size: ${px2rem(26)};
  color: #ffffff;
  font-weight: 600;
  padding: ${px2rem(10)} ${px2rem(30)};
  position: relative;
  cursor: pointer;
}
`;

const PeriodListMobile = styled.div`
@media screen and ${DEVICES.TOUCH} {
  background-color: #1f284c;
  box-shadow: 0 ${px2rem(4)} ${px2rem(16)} 0 rgba(0, 0, 0, .5);
  border-radius: ${px2rem(4)};
  overflow: hidden;
  max-height: ${(props) => props.open === 1 ? px2rem((props.listLen || 1) * 90) : px2rem(90)};
  transition: max-height 300ms;
  ${PeriodBtnMobile} {
    &:first-child {
      &::after {
        display: ${(props) => props.listLen <= 1 ? 'none' : 'block'};
        transform: ${(props) => props.open === 1 ? 'rotate(180deg)' : 'rotate(0)'};
      }
    }
  }
}
`;

const PeriodItemMobile = styled.div`
@media screen and ${DEVICES.TOUCH} {
  &:first-child {
    ${PeriodBtnMobile} {
      &::after {
        display: block;
        content: "";
        width: ${px2rem(18)};
        height: ${px2rem(10)};
        position: absolute;
        top: ${px2rem(40)};
        right: ${px2rem(30)};
        background: url("${IconArrowDownWhite}") center center no-repeat;
        background-size: 100% auto;
        transition: transform 300ms;
      }
    }
  }
}
`;

const BlockNumberMobile = styled.span`
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  font-weight: 200;
}
`;

class CompPeriodSelector extends React.Component {
  constructor() {
    super();
    this.state = {
      open: 0, // 0: closed, 1: open
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.onMobileItemClick = this.onMobileItemClick.bind(this);
    this.onMobileCurrentDisplayClick = this.onMobileCurrentDisplayClick.bind(this);
  }

  render() {
    const currentItem = this.props.list[this.props.index] || null;
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
             <div>
               <PeriodList className="columns is-gapless is-hidden-touch">
                {
                  this.props.list && this.props.list.length > 0 && (
                    this.props.list.map((item, index) => (
                      <PeriodItem className="column is-center is-narrow" current={index === this.props.index} key={index}>
                        <PeriodContent>
                          <RowDate onClick={() => this.onItemClick(index)}>
                            {t('estimateDate', {date: new Date(item.DistributeTime * 1000).toLocaleDateString(i18n.language)})}
                          </RowDate>
                          <RowBlockNumber>
                            {t('blockNumberRange', {value: `${item.StartBlockNumber}-${item.EndBlockNumber}`})}
                          </RowBlockNumber>
                        </PeriodContent>
                      </PeriodItem>
                    ))
                  )
                 }
                </PeriodList>
              </div>

              <PeriodMobileContainer className="is-hidden-desktop">
                <PeriodListMobile
                open={this.state.open}
                listLen={(this.props.list && this.props.list.length) || 0}>
                  {
                    this.props.list && this.props.list.length > 0 && (
                      this.props.list.map((item, index) => (
                        <PeriodItemMobile current={index === this.props.index} key={index}>
                          <PeriodBtnMobile onClick={() => this.onMobileItemClick(index)}>
                            {t('estimateDate', {date: new Date(item.DistributeTime * 1000).toLocaleDateString(i18n.language)})}
                            <BlockNumberMobile>
                              &nbsp;&nbsp;
                              {t('blockNumberRange', {value: `${item.StartBlockNumber}-${item.EndBlockNumber}`})}
                            </BlockNumberMobile>
                          </PeriodBtnMobile>
                        </PeriodItemMobile>
                      ))
                    )
                  }
                </PeriodListMobile>

                {
                  this.state.open === 0 && currentItem && (
                    <CurrentPeriodDisplayWrapper>
                      <PeriodBtnMobile onClick={this.onMobileCurrentDisplayClick}>
                        {t('estimateDate', {date: new Date(currentItem.DistributeTime * 1000).toLocaleDateString(i18n.language)})}
                        <BlockNumberMobile>
                          &nbsp;&nbsp;
                          {t('blockNumberRange', {value: `${currentItem.StartBlockNumber}-${currentItem.EndBlockNumber}`})}
                        </BlockNumberMobile>
                      </PeriodBtnMobile>
                    </CurrentPeriodDisplayWrapper>
                  )
                }
              </PeriodMobileContainer>
            </Container>
          )
        }
      </Translation>
    );
  }

  onItemClick(index) {
    if (this.props.changeList) {
      this.props.changeList(index);
    }
  }

  onMobileItemClick(index) {
    if (!this.props.list || this.props.list.length <= 1) {
      return;
    }
    if (this.state.open === 0) {
      if (index !== 0) {
        return;
      }

      this.setState({
        open: 1,
      });

      return;
    }

    this.setState({
      open: 0,
    });
    if (this.props.changeList) {
      this.props.changeList(index);
    }
  }

  onMobileCurrentDisplayClick() {
    this.onMobileItemClick(0);
  }
}

export default CompPeriodSelector;
