export function formatVestDisplay(vest = '') {
  if (!vest || vest === '0' || vest === '0.0') {
    return 'N/A';
  }
  return `${vest} VEST`;
}

export function formatPercent(num) {
  if (!num || num === '0' || num === '0.0') {
    return 'N/A';
  }
  return `${Math.floor((num * 100) || 0)}%`;
}
