import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import RuleBanner from '../components/RuleBanner';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
background-color: #f6f6f6;
padding: 0 0 40px 0;
@media screen and ${DEVICES.TOUCH} {
  padding: ${px2rem(20)} ${px2rem(30)} ${px2rem(60)} ${px2rem(30)};
}
`;

const ContentContainer = styled.div`
background-color: #ffffff;
padding: 20px 30px 20px 30px;
@media screen and ${DEVICES.TOUCH} {
  padding: 0 0 ${px2rem(20)} 0;
}
`;

const Desc1 = styled.div`
text-align: left;
color: #333333;
font-size: 14px;
line-height: 1.7;
padding: 20px 20px 40px 0;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  padding: ${px2rem(15)} ${px2rem(20)} ${px2rem(60)} ${px2rem(20)};
}
`;

const Title = styled.h2`
font-weight: 600;
font-size: 18px;
color: #333333;
margin-bottom: 10px;
@media screen and ${DEVICES.TOUCH} {
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
  padding-left: ${px2rem(20)};
}
`;

const RuleItem  = styled.div`
margin-bottom: 20px;
@media screen and ${DEVICES.TOUCH} {
  margin-bottom: ${px2rem(40)};
  padding: 0 ${px2rem(20)};
}
`;

const RuleItemTitle = styled.div`
font-size: 14px;
color: #333333;
font-weight: 600;
margin-bottom: 4px;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  margin-bottom: ${px2rem(17)};
}
`;

const RuleTextWrapper = styled.ul`
list-style: disc;
padding-left: 20px;
@media screen and ${DEVICES.TOUCH} {
  padding-left: ${px2rem(32)};
}
`;

const RuleItemText = styled.li`
font-size: 14px;
color: #333333;
line-height: 1.7;
list-style: disc;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
}
`;

class RulesView extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <ContentContainer className="container">
                <RuleBanner></RuleBanner>

                <Desc1 dangerouslySetInnerHTML={{__html: t('ruleDesc1')}}></Desc1>

                <Title>
                  {t('descTitle')}
                </Title>

                <RuleItem>
                  <RuleItemTitle>
                    {t('ruleItemTitle01')}
                  </RuleItemTitle>
                  <RuleTextWrapper>
                    <RuleItemText dangerouslySetInnerHTML={{__html: t('ruleItem01Desc01')}}>
                    </RuleItemText>
                    <RuleItemText>
                    {t('ruleItem01Desc02')}
                    </RuleItemText>
                    <RuleItemText>
                    {t('ruleItem01Desc03')}
                    </RuleItemText>
                  </RuleTextWrapper>
                </RuleItem>

                <RuleItem>
                  <RuleItemTitle>
                    {t('ruleItemTitle02')}
                  </RuleItemTitle>

                  <RuleTextWrapper>
                    <RuleItemText>
                      {t('ruleItem02Desc01')}
                    </RuleItemText>
                    <RuleItemText>
                      {t('ruleItem02Desc02')}
                    </RuleItemText>
                    <RuleItemText>
                      {t('ruleItem02Desc03')}
                    </RuleItemText>
                  </RuleTextWrapper>
                </RuleItem>

                <RuleItem>
                  <RuleItemTitle>
                    {t('ruleItemTitle03')}
                  </RuleItemTitle>
                  <RuleTextWrapper>
                    {/* <RuleItemText>
                      {t('ruleItem03Desc02')}
                    </RuleItemText> */}
                    <RuleItemText>
                      {t('ruleItem03Desc03')}
                    </RuleItemText>
                  </RuleTextWrapper>
                </RuleItem>

                <Title>
                  {t('descTitleGuide')}
                </Title>
                <RuleItem>
                <RuleTextWrapper>
                  <RuleItemText dangerouslySetInnerHTML={{__html: t('ruleItem04Desc01')}}>
                  </RuleItemText>
                  <RuleItemText dangerouslySetInnerHTML={{__html: t('ruleItem04Desc02')}}>
                  </RuleItemText>
                </RuleTextWrapper>
                </RuleItem>
              </ContentContainer>
            </Container>
          )
        }
      </Translation>
    );
  }
}

export default RulesView;
