import React from 'react';
import styled from 'styled-components';
import './App.css';
import {I18nextProvider} from 'react-i18next';
import i18nInstance from './i18n/index';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import routes from './routes';
import HeaderWrapper from './components/HeaderWrapper';
import Footer from './components/Footer';
import CompPageBanner from './components/PageBanner';
import { px2rem, DEVICES} from './utils/styles';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-150296528-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const AppRouterContainer = styled.div`
min-height: 100%;
margin-bottom: -60px;
padding-bottom: 60px;
@media screen and ${DEVICES.TOUCH} {
  margin-bottom: ${px2rem(-90)};
  padding-bottom: ${px2rem(90)};
}
`;

function App() {
  return (
    <div className="App">
      <I18nextProvider i18n={i18nInstance} defaultNS="home">
        <AppRouterContainer>
        <Router>
          <HeaderWrapper></HeaderWrapper>
          <CompPageBanner></CompPageBanner>
          <Switch>
            {routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))}
          </Switch>
        </Router>
        </AppRouterContainer>

        <Footer></Footer>
      </I18nextProvider>
    </div>
  );
}

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

export default App;
