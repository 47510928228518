import React from 'react';
import styled from 'styled-components';
import { Translation } from 'react-i18next';
import BgBanner from '../assets/images/bg_banner.png';
import BgBannerMobile from '../assets/images/bg_banner_mobile.png';
import Nav from '../components/Nav';
import {getHistoricalVoteInfo} from '../api/apiHome';
import { px2rem, DEVICES} from '../utils/styles';

const Container = styled.div`
height: 219px;
background: #242e55 url(${BgBanner}) center center no-repeat;
background-size: 100% 100%;
position: relative;
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(264)};
  background: #242e55 url(${BgBannerMobile}) center center no-repeat;
  background-size: 100% 100%;
}
`;

const ContentWrapper = styled.div`
position: relative;
height: 219px;
@media screen and ${DEVICES.TOUCH} {
  height: ${px2rem(264)};
}
`;

const Title = styled.div`
text-align: left;
color: #ffffff;
font-size: 36px;
font-weight: 600;
padding-top: 30px;
@media screen and ${DEVICES.TOUCH} {
  text-align: center;
  font-size: ${px2rem(36)};
  padding-top: ${px2rem(40)};
}
`;

const InfoWrapper = styled.div`
padding-top: 11px;
@media screen and ${DEVICES.TOUCH} {
  padding-top: ${px2rem(13)};
}
`;

const Info = styled.div`
font-size: 18px;
color: #ffffff;
text-align: left;
@media screen and ${DEVICES.TOUCH} {
  font-size: ${px2rem(24)};
  text-align: center;
}
`;

const JoinLink = styled.a`
display: inline-block;
background-color: #17bafe;
border-radius: 28px;
font-size: 14px;
color: #ffffff;
padding: 5px 18px;
margin-left: 20px;
text-decoration: none;
@media screen and ${DEVICES.TOUCH} {
  border-radius: ${px2rem(34)};
  font-size: ${px2rem(24)};
  padding: ${px2rem(12)} ${px2rem(28)};
  margin: ${px2rem(34)} auto 0 auto;
  margin-left: auto;
}
`;

class CompPageBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      VotersNumber: '',
      MaxROI: '',
    };
  }

  render() {
    return (
      <Translation ns="home">
        {
          (t, { i18n }) => (
            <Container>
              <ContentWrapper className="container">
                <Title>
                  {t('bannerTitle')}
                </Title>

                <InfoWrapper>
                  <Info>
                    {t('bannerInfo', {user_count: this.state.VotersNumber})}
                    &nbsp;<JoinLink href="https://cos.tv/wallet/bp_vote">{t('joinLink')}</JoinLink>
                  </Info>
                </InfoWrapper>

                <Nav></Nav>
              </ContentWrapper>
            </Container>
          )
        }
      </Translation>
    );
  }

  componentDidMount() {
    this.getHistoricalVoteInfo();
  }

  async getHistoricalVoteInfo() {
    try {
      const response = await getHistoricalVoteInfo();

    if (response.status === 200 && response.data && response.data.Status === 200) {
      this.setState({
        VotersNumber: response.data.Info.VotersNumber,
        MaxROI: response.data.Info.MaxROI,
      });
    }
    } catch(error) {

    }
  }

  formatPercent(num) {
    return `${((num * 100) || 0).toFixed(1)}%`;
  }
}

export default CompPageBanner;
