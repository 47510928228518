export const BREAK_POINT_MOBILE = '768px';
export const BREAK_POINT_TOUCH = '1064px';
export const BREAK_POINT_DESKTOP = '1264px';

export function px2rem(px = 0) {
  return `${px * 31.25 / 750}rem`;
}

export function clearfix() {
  return `
    :before,
    :after {
      content: " ";
      display: table;
    }
    :after {
      clear: both;
    }
    zoom: 1;
  `;
}

export function overflowEllipsis() {
  return `
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `;
}

export const DEVICES = {
  TOUCH: `(max-width: ${BREAK_POINT_TOUCH})`,
};
