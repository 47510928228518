import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: '/',
  timeout: 1000 * 60 * 5,
});

axiosInstance.interceptors.request.use(config => {
  return config;
});
axiosInstance.interceptors.response.use(null, () => Promise.resolve(null));

export default axiosInstance;
